import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import { Document, Page } from 'react-pdf';
import priceList from 'assets/img/gallery/pricelist/pricelist5.png'

import styles from "assets/jss/material-kit-react/views/landingPageSections/openingHoursAndPriceListStyle.js";

const useStyles = makeStyles(styles);

export default function PricelistSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Cenník</h2>
          {/* <h5 className={classes.description}> 
          Vítame ťa na stránke nášho fitness centra.Veríme,že ťa obsah zaujal natoľko,že sa k nám prídeš pozrieť osobne a staneme sa súčasťou tvojho života. Ak ešte stále váhaš dáme ti pár argumentov ktoré ti tvoje rozhodnutie môžu uľahčiť.
          </h5> */}
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <img src={priceList} alt="..." className={classes.imgFluidPosters}/>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
