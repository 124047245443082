import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>O nás</h2>
          <h3 className={classes.description}> {/*//TODO: toto je skopcene z GG stranky, nieco vymysliet vlastne*/}
              Skvelé vybavenie, rodinná atmosféra, priateľský kolektív, 
              milý a ochotný personál - to všetko ti ponúka Star-gym! 
              Tak neváhaj a pridaj sa k nám ;)
          </h3>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
           {/*//TODO: nieco o vybaveni*/}
            <InfoArea
              title="Skvelé vybavenie"
              // description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={FitnessCenter}
              iconColor="info"
              vertical
            />
          </GridItem>
           {/*//TODO: nieco o kolektive*/}
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Priateľský kolektív"
              // description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={EmojiPeopleIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
           {/*//TODO: nieco o personale*/}
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Milý a ochotný personál"
              // description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={EmojiEmotionsIcon}
              iconColor="warning"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
