import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import priceList from 'assets/img/gallery/openinghours/openinghours.png'

import styles from "assets/jss/material-kit-react/views/landingPageSections/openingHoursAndPriceListStyle.js";

const useStyles = makeStyles(styles);

export default function OpeningHoursSection() {
  const classes = useStyles();
  
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Otváracie hodiny</h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <img src={priceList} alt="..." className={classes.imgFluidPosters}/>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
