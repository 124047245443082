import { title } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const productStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  ...imagesStyle,
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#999"
  },
  icon: {
    position: "relative",
    top: "3px",
    color: "#ff972b"
  },
  contactsInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  aEmailLink: {
    color: "#000"
  }
};

export default productStyle;
