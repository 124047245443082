/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  const scrollToHome = () => window.scrollTo({ top: 0, behavior: "smooth"});
  
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Kategórie"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          hoverColor="warning"
          buttonIcon={Apps}
          dropdownList={[
            <div
              className={classes.dropdownLink}
              onClick={scrollToHome}
            >
              Domov
            </div>,

            <div
              className={classes.dropdownLink}
              onClick={props.scrollToProductSection}
            >
              O nás
            </div>,

            // <div
            //   className={classes.dropdownLink}
            //   onClick={props.scrollToTeamSection}
            // >
            //   Náš tím
            // </div>,

            <div
              className={classes.dropdownLink}
              onClick={props.scrollToOpeningHoursSection}
            >
              Otváracie hodiny
            </div>,

            <div
              className={classes.dropdownLink}
              onClick={props.scrollToPricelistSection}
            >
              Cenník
            </div>,

            <div
            className={classes.dropdownLink}
            onClick={props.scrollToContactsSection}
            >
              Kontakt
            </div>,

            // <div
            //   className={classes.dropdownLink}
            //   onClick={props.scrollToCarouselSection}
            // >
            //   Galéria - old
            // </div>,

            // <Link to="/profile-page" className={classes.dropdownLink}>
            //   Galéria  
            // </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>    
        <Tooltip
          id="instagram-facebook"
          title="Sleduj nás na facebooku"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/stargymnz"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Sleduj nás na instagrame"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim" //TODO: ziskat ig link
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem> */}
    </List>
  );
}
