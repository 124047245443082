import React, { useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import logo from "assets/img/gallery/logos/logo_new.png";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import CarouselSection from "./Sections/CarouselSection.js";
import PricelistSection from "./Sections/PricelistSection.js";
import OpeningHoursSection from "./Sections/OpeningHoursSection.js";
import ContactsSection from "./Sections/ContactsSection.js"

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const productSectionRef = useRef(null);
  const scrollToProductSection = () => window.scrollTo({ top: productSectionRef.current.offsetTop, behavior: "smooth"});

  const teamSectionRef = useRef(null);
  const scrollToTeamSection = () => window.scrollTo({ top: teamSectionRef.current.offsetTop, behavior: "smooth"});

  const pricelistSectionRef = useRef(null);
  const scrollToPricelistSection = () => window.scrollTo({ top: pricelistSectionRef.current.offsetTop, behavior: "smooth"});
  
  const openingHoursSectionRef = useRef(null);
  const scrollToOpeningHoursSection = () => window.scrollTo({ top: openingHoursSectionRef.current.offsetTop, behavior: "smooth"});

  const contactsSectionRef = useRef(null);
  const scrollToContactsSection = () => window.scrollTo({ top: contactsSectionRef.current.offsetTop, behavior: "smooth"});

  // const carouselSectionRef = useRef(null);
  // const scrollToCarouselSection = () => window.scrollTo({ top: carouselSectionRef.current.offsetTop, behavior: "smooth"});

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Star-gym fitness"
        rightLinks={<HeaderLinks 
          scrollToProductSection={scrollToProductSection}
          scrollToTeamSection={scrollToTeamSection}
          scrollToOpeningHoursSection={scrollToOpeningHoursSection}
          scrollToPricelistSection={scrollToPricelistSection}
          scrollToContactsSection={scrollToContactsSection}
          />
        }
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      {/* pridat do parallaxu ked tak parameter filter na zapnutie zasedenia obrazku */}
      <Parallax filter image={require("assets/img/gallery/equipment/Parallax.JPG")}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              {/* <h1 className={classes.title}>Star-gym</h1> */}
              <img src={logo} alt="..." className={imageClasses} /> {/*TODO: dodat fotky a popisky ludi*/}
              {/* <h3>
              Otváracie hodiny: <br />
              Pondelok: &emsp; 7:00 – 21:00 <br />
              Utorok: &emsp; &emsp; 7:00 – 21:00 <br />
              Streda: &emsp; &emsp; 7:00 – 21:00 <br />
              Štvrtok: &emsp; &emsp; 7:00 – 21:00 <br />
              Piatok: &emsp; &emsp;&nbsp; 7:00 – 21:00 <br />
              Sobota: &emsp; &emsp; 8:00 – 21:00 <br />
              Nedeľa: &emsp; &emsp; 8:00 – 21:00 <br />
              </h3> */}

              {/* <h4>
              Skvelé vybavenie, rodinná atmosféra, priateľský kolektív, 
              milý a ochotný personál - to všetko ti ponúka Star-gym! 
              Tak neváhaj a pridaj sa k nám ;)
              </h4> */}

              <br />
              {/* <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)} ref={productSectionRef}>
        <div className={classes.container}>
          <ProductSection />
        </div>
      </div>
      {/* <div className={classes.container} ref={teamSectionRef}>
        <TeamSection />
      </div> */}

      {/* <div className={classNames(classes.main, classes.mainRaised)} ref={carouselSectionRef}> */}
        <div className={classes.container} ref={openingHoursSectionRef}>
          <OpeningHoursSection />
        </div>
      {/* </div> */}

      <div className={classNames(classes.main, classes.mainRaised)} ref={pricelistSectionRef}>
        <div className={classes.container} ref={pricelistSectionRef}>
          <PricelistSection />
        </div>
      </div>

      <div className={classes.container} ref={contactsSectionRef}>
          <ContactsSection />
      </div>

      {/* <div className={classNames(classes.main, classes.mainRaised)} ref={carouselSectionRef}>
        <div className={classes.container}>
          <CarouselSection />
        </div>
      </div> */}
      <Footer />
    </div>
  );
}
