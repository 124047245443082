import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { LocationOn, Phone, Email, AlternateEmail } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import styles from "assets/jss/material-kit-react/views/landingPageSections/contactsStyle.js";

const useStyles = makeStyles(styles);

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={17}
    defaultCenter={{ lat: 47.98081383606778, lng: 18.163765700313384 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: 47.98081383606778, lng: 18.163765700313384 }} />}
  </GoogleMap>
))

export default function OpeningHoursSection() {
  const classes = useStyles();
  
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Kontakt</h2>
          <div className={classes.contactsInfo}>
            <h4 className={classes.title}>  
              <LocationOn className={classes.icon} /> G. Bethlena 12, 940 58 Nové Zámky<br />
            </h4>
            <h4 className={classes.title}>  
              <Phone className={classes.icon} /> 0903 651 052<br />
            </h4>
            <h4 className={classes.title}>  
              <Email className={classes.icon} /> stargym@rasl.sk
            </h4>
          </div>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <MyMapComponent
              isMarkerShown
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBCec2k7L_97gfOXQAGp9WQ1zhDRw0JtRc&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
